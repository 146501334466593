<template>
  <div class="login">
    <div class="login-invite" v-if="inviterMobile !== ''">
      您被尾号{{
        inviterMobile.substring(inviterMobile.length - 4)
      }}邀请免费使用云算房计价软件
    </div>
    <div class="login-title"></div>
    <div class="login-form">
      <div class="login-form-username">
        <van-field
          v-model="username"
          class="login-form-username-field"
          placeholder="联系人名称"
        />
      </div>
      <div class="login-form-mobile">
        <van-field
          v-model="mobile"
          class="login-form-mobile-field"
          type="tel"
          maxlength="11"
          placeholder="手机号"
        />
      </div>
      <div class="login-form-code">
        <div class="login-form-code-input">
          <van-field
            v-model="smscode"
            class="login-form-code-input-field"
            maxlength="6"
            type="tel"
            placeholder="短信验证码"
          />
        </div>
        <div class="login-form-code-action">
          <van-button
            type="primary"
            :disabled="codeDisabled"
            class="login-form-code-action-btn"
            @click="onClickSendSms"
          >
            {{ codeText }}
          </van-button>
        </div>
      </div>
      <div class="login-form-login" @click="onClickLogin"></div>
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
import Vue from "vue";
import { mobile_smsCode, mobile_login, applyRecommend } from "@/api/invite";
export default Vue.extend({
  props: {
    inviterMobile: {
      type: String,
      default: () => {
        return "";
      },
    },
    invitationMethod: {
      type: String,
      default: () => {
        return "0";
      },
    },
  },
  data() {
    return {
      username: "",
      mobile: "",
      smscode: "",
      codeText: "获取验证码",
      codeDisabled: false,
      timer: null,
    };
  },
  methods: {
    onClickSendSms() {
      if (!this.codeDisabled) {
        if (!this.mobile || !/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.mobile)) {
          Notify("请输入正确手机号");
        } else {
          if (!this.timer) {
            let time = 60;
            this.codeText = `${time}秒后重新获取`;
            this.codeDisabled = true;
            this.timer = setInterval(() => {
              if (time > 1) {
                time = time - 1;
                this.codeText = `${time}秒后重新获取`;
              } else {
                clearInterval(this.timer);
                this.codeText = "重新发送";
                this.codeDisabled = false;
                this.timer = null;
              }
            }, 1000);
          }
          //发送验证码
          mobile_smsCode(this.mobile).then((res) => {
            // if (res.code == 200) {
            // }
          });
        }
      }
    },
    onClickLogin() {
      if (this.inviterMobile !== "" && this.username === "") {
        Notify("请输入联系人名称");
      } else if (!this.mobile || !/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.mobile)) {
        Notify("请输入正确手机号");
      } else if (this.smscode === "") {
        Notify("请输入验证码");
      } else {
        //登录
        const formData = new FormData();
        formData.append("grant_type", "sms");
        formData.append("loginChannel", "PLATFORM_DEFAULT");
        formData.append("scope", "all");
        formData.append("mobile", this.mobile);
        formData.append("sms_code", this.smscode);
        formData.append("contacts", this.username);
        formData.append("loginType", 11);

        mobile_login(formData).then((res) => {
          this.$store.commit("SET_INVITE_TOKEN", res.access_token);
          localStorage.setItem("inviteToken", res.access_token);
          this.$store.commit("SET_CURRENT_LOGIN_MOBILE", this.mobile);
          localStorage.setItem("currentLoginMobile", this.mobile);
          if (this.inviterMobile !== "" && this.inviterMobile !== this.mobile) {
            this.ApplyRecommend();
          }
          let info = `inviterMobile,${this.mobile}_invitationMethod,1`;
          this.$router.replace({
            path: `/record?info=${info}`,
          });
        });
      }
    },
    ApplyRecommend() {
      let reqData = {
        invitationMethod: this.invitationMethod,
        contacts: this.username,
        inviteeMobile: this.mobile,
        inviterMobile: this.inviterMobile,
      };
      window.sessionStorage.setItem("isSubmitToken", "NoExit");
      applyRecommend(reqData, this.$store.getters.inviteToken).then((res) => {
        if (res) {
          Notify({
            type: "success",
            message: "您已成功被推荐为公测免费试用名额",
          });
        }
      });
    },
  },
});
</script>
<style lang="less" scoped>
.login {
  background-color: #fff;
  padding-bottom: 40px;
  margin: 0 20px;
  border-radius: 10px;
  padding-top: 34px;
  &-invite {
    font-size: 13px;
    color: #fb5b03;
    text-align: center;
    margin-bottom: 20px;
  }
  &-title {
    background-image: url("../../../assets/img/invite/login_title.png");
    background-size: 100% 100%;
    width: 190px;
    height: 20px;
    margin: 0 auto;
  }
  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 18px;
    &-username {
      width: 280px;
      margin-bottom: 10px;
      &-field {
        background-color: #f0f4ff;
        border-radius: 5px;
      }
    }
    &-mobile {
      margin-bottom: 10px;
      width: 280px;
      &-field {
        background-color: #f0f4ff;
        border-radius: 5px;
      }
    }
    &-code {
      display: flex;
      align-items: center;
      width: 280px;
      margin-bottom: 20px;
      &-input {
        flex: 1;
        &-field {
          background-color: #f0f4ff;
          border-radius: 5px;
        }
      }
      &-action {
        font-size: 0px;
        margin-left: 10px;
        &-btn {
          border-radius: 5px;
          background-color: #fce40f;
          border-color: #fce40f;
          color: #391916;
          width: 140px;
        }
      }
    }
    &-login {
      width: 280px;
      height: 50px;
      background-image: url("../../../assets/img/invite/login.png");
      background-size: 100% 100%;
    }
  }
}
</style>
